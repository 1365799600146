import { Button } from 'antd';
import { useGoogleLogout } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { logout } from '../store/actions';

const Logout: React.FC = () => {
    const dispatch = useDispatch();

    const { signOut } = useGoogleLogout({
        clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID || '',
        onLogoutSuccess: () => {
            dispatch(logout());
        },
        onFailure: () => {
            dispatch(logout());
        },
    });

    const handleLogOut = () => {
        signOut();
    };

    return <Button onClick={handleLogOut}>Logout</Button>;
};

export default Logout;
