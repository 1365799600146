import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import TimeLine, { Timelines } from '../features/customerDetail/timelines';
import TransactionsDetail from '../features/customerDetail/Transactions';
import PageTitle from '../components/ui/PageTitle';
import CustomerInfo from '../features/customerDetail/CustomerInfo';
import Memberships from '../features/customerDetail/Memberships';
import Rebill from '../features/customerDetail/Rebill';
import { useGetCustomerQuery } from '../services/customer';
import LoadingSkeleton from '../components/ui/LoadingSkeleton';
import ErrorAlertBox from '../components/ui/ErrorAlertBox';
import useCancelMembership from '../hooks/useCancelMembership';
import useRefundTransactions from '../hooks/useRefundTransactions';
import Usage from '../features/customerDetail/Usage';
import LoginLogs from '../features/customerDetail/LoginLogs';
import { DetailsLayout } from '../features/customerDetail/customerDetail.styled';
import SideDrawer from '../components/layout/SideDrawer';
import useCreateNote from '../hooks/useCreateNote';
import XSales from '../features/customerDetail/Xsales';
import useScrollToTop from '../hooks/useScrollToTop';
import useSendEmail from '../hooks/useSendEmail';
import useUpdateEmail from '../hooks/useUpdateEmail';

const CustomerDetails: React.FC = () => {
    const [visible, setVisible] = useState(false);
    const [timeline, setTimeline] = useState<Timelines>('Transactions');
    const { id: customerId } = useParams<'id'>();
    const { cancelMembership, isCancelling } = useCancelMembership();
    const { refundTransactions, isRefunding } = useRefundTransactions();
    const { data, error: customerError, isFetching: isFetchingCustomer } = useGetCustomerQuery(+customerId!);
    const { createNote, isCreatingNote } = useCreateNote();
    const { sendRefundEmail, sendCancelEmail, isLoading: isSendingEmail } = useSendEmail();
    const { updateEmail } = useUpdateEmail();

    useScrollToTop();

    const onClose = () => {
        setVisible(false);
    };

    const cancelWithNote = async (id: number, note?: string) => {
        const result = await cancelMembership(id);
        if (note && result) await createNote(note, +customerId!);
    };

    const refundWithNote = async (transactions: number[], note?: string) => {
        const result = await refundTransactions(transactions, customerId!);
        if (note && result) await createNote(note, +customerId!);
    };

    const sendRefundEmailWithNote = async (transactions: number[], note?: string) => {
        const result = await sendRefundEmail(transactions, customerId!);
        if (note && result) await createNote(note, +customerId!);
    };

    const sendCancelEmailWithNote = async (id: number, note?: string) => {
        const result = await sendCancelEmail(id);
        if (note && result) await createNote(note, +customerId!);
    };

    const handleUpdateEmail = (newEmail: string) => {
        if (data?.email !== newEmail) updateEmail(newEmail, +customerId!);
    };

    const toggleDrawer = (timelineName: Timelines = 'Transactions') => {
        if (!visible && timeline !== timelineName) setTimeline(timelineName);
        setVisible((visibleState) => !visibleState);
    };

    const errorMsg = (customerError && 'data' in customerError && customerError.data?.error) || undefined;

    // ERROR STATE
    if (customerError)
        return (
            <ErrorAlertBox
                message="There was an error loading this customer. Try refreshing the page."
                errorInfo={errorMsg}
                returnToPageUrl="/"
            />
        );

    // LOADING STATE
    if (isFetchingCustomer || !data)
        return (
            <DetailsLayout>
                <PageTitle title={`Customers > ${customerId}`} />
                <LoadingSkeleton amount={3} />
            </DetailsLayout>
        );

    return (
        <DetailsLayout>
            <PageTitle title={`Customers > ${customerId}`} />

            <CustomerInfo info={data} showTimeline={() => toggleDrawer('Notes')} updateEmail={handleUpdateEmail} />

            <Memberships
                cancelInitiated={isCancelling || isCreatingNote}
                sendCancelEmailInitiated={isSendingEmail}
                customerId={+customerId!}
                cancel={cancelWithNote}
                sendCancelEmail={sendCancelEmailWithNote}
                data={data}
                showTimeline={() => toggleDrawer('Logins')}
                memberStatus={data.membershipStatus} // THIS ADDED IN SEPARATELY FOR CLARITY
            />

            {data.rebill && <Rebill data={data.rebill} />}

            <TransactionsDetail
                refund={refundWithNote}
                resendEmail={sendRefundEmailWithNote}
                isSendingEmail={isSendingEmail}
                isrefunding={isRefunding || isCreatingNote}
                data={data.payments}
            />

            <XSales dataset={data.xsales} />

            <Usage data={data.usageStats || {}} />

            <LoginLogs dataset={data.loginLogs || []} showTimeline={() => toggleDrawer('Logins')} />

            <SideDrawer onClose={onClose} visible={visible}>
                <TimeLine transactions={data.payments} logins={data.loginLogs} show={timeline} />
            </SideDrawer>
        </DetailsLayout>
    );
};

export default CustomerDetails;
