/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCreateCustomerNoteMutation } from '../services/customer';
import { fetchWithNotification } from '../utils/fetchWithNotification';

const useCreateNote = () => {
    const [createNote, { isLoading, isError, error }] = useCreateCustomerNoteMutation();

    const createNoteWithNotification = async (note: string, id: number) => {
        await fetchWithNotification(() => createNote({ customerId: id, text: note }), {
            success: 'Customer note created',
            fail: 'Failed to create note',
        });
    };

    return {
        createNote: createNoteWithNotification,
        isCreatingNote: isLoading,
        isCreateNoteError: isError,
        newNoteError: error,
    };
};

export default useCreateNote;
