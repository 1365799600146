/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

export async function fetchWithNotification<T>(callback: () => Promise<T>, options: { success: string; fail: string }) {
    try {
        const result = await callback();
        if (!result || 'error' in result) throw new Error();
        notification.open({
            duration: 15,
            message: options.success,
            icon: <CheckCircleOutlined data-testid="successNotification" style={{ color: 'var(--green)' }} />,
        });
        return result;
    } catch (e) {
        notification.open({
            duration: 15,
            message: options.fail,
            icon: <CloseCircleOutlined data-testid="failNotification" style={{ color: 'red' }} />,
        });
    }
}
