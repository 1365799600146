import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import { store } from './store';
import ErrorAlertBox from './components/ui/ErrorAlertBox';

const environment = process.env.REACT_APP_ENV;

if (environment && environment !== 'local' && process.env.NODE_ENV !== 'test') {
    Sentry.init({
        environment,
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // Adjust this value in production
        tracesSampleRate: 0.5,
    });
}

ReactDOM.render(
    <Provider store={store}>
        <Sentry.ErrorBoundary fallback={<ErrorAlertBox message="Oops, something went wrong" />}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Sentry.ErrorBoundary>
    </Provider>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
