/* eslint-disable prettier/prettier */
import { InfoCircleOutlined } from '@ant-design/icons';
import { Table, Tooltip } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { Payment } from '../../types';
import { getCurrencyWithDecimals, getDateTimeString } from '../../utils/helpers';
import HoverInfo from '../../components/ui/HoverInfo';
import SectionHeader from '../../components/ui/SectionHeader';
import TransactionStatusTag from '../../components/ui/TransactionStatusTag';
import TransactionTypeTag from '../../components/ui/TransactionTypeTags';
import PopConfirmWithNote from '../notes/PopConfirmWithNote';
import { Section } from './customerDetail.styled';


export type TransactionsDetailProps = {
    data: Payment[];
    isrefunding: boolean;
    isSendingEmail: boolean;
    refund: (ids: number[], note?: string) => void;
    resendEmail: (ids: number[], note?: string) => void;
};

const TransactionsDetail: React.FC<TransactionsDetailProps> = ({ data, refund, isrefunding, isSendingEmail, resendEmail }) => {
    const [selectedKeys, setSelectedKeys] = useState<React.Key[]>([]);

    useEffect(() => {
        if (!isrefunding) setSelectedKeys([]);
    }, [isrefunding]);

    const rowSelection = {
        selectedRowKeys: selectedKeys,
        onChange: (selectedRowKeys: React.Key[]) => {
            setSelectedKeys(selectedRowKeys);
        },
        // getCheckboxProps: (record: Payment) => ({
        //     disabled: !(record.isRefundable || record.type === 'Refund')
        // }),
    };

    const dataSet = useMemo(() => data, [data]);

    const handleRefund = (vals: any) => {
        const submission = vals as { note: string };
        const ids = [...selectedKeys] as number[];
        refund(ids, submission.note);
    };

    const handleResendEmail = (vals: any) => {
        const submission = vals as { note: string };
        const ids = [...selectedKeys] as number[];
        resendEmail(ids, submission.note);
    };


    function findRefundedAmount(amount: number): string {
        const foundRefund = dataSet.find(
            (transaction) => transaction.type === 'Refund' && transaction.amount === amount
        );
        if (foundRefund && foundRefund.amount)
            return `-${getCurrencyWithDecimals(foundRefund.currency, foundRefund.amount)}`;
        return '';
    }

    function billerAndOriginalAmount(currency: string, billerFee: number, originalAmount: number) {
        const hoverValues = [];
        hoverValues.push(`Biller fee: $${billerFee}`);
        if (currency && currency !== 'USD') hoverValues.push(`Original amount: ${getCurrencyWithDecimals(currency, originalAmount)} ${currency}`);
        return hoverValues;
    }

    function selectedRowsAreRefundTransactions(ids: React.Key[], transactions: Payment[]) {
        return ids.every(id => {
            const selectedTransaction = transactions.find((payment: Payment) => payment.id === id);
            return selectedTransaction?.type === 'Refund' && selectedTransaction.status === 'SUCCEED';
            // return selectedTransaction?.status === 'FAILED'
            // return selectedTransaction?.status === 'SUCCEED'
        })
    }

    function selectedRowsAreRefundable(ids: React.Key[], transactions: Payment[]) {
        return ids.every(id => {
            const selectedTransaction = transactions.find((payment: Payment) => payment.id === id);
            return selectedTransaction?.isRefundable
        })
    }


    return (
        <Section as="section">
            <div style={{ width: '100%' }}>
                <SectionHeader title="Transactions">
                    {(selectedKeys.length > 0) && (
                        <div>
                            <>
                                {selectedRowsAreRefundTransactions(selectedKeys, data) && (
                                    <PopConfirmWithNote
                                        isLoading={isSendingEmail}
                                        onCreate={handleResendEmail}
                                        formTitle="Resend refund confirmation email"
                                        btnText="Resend Email"
                                        btnType="ghost"
                                        submitText="Resend"
                                        exitText="Exit"
                                        extraContent={<p> This will resend the refund confirmation email for the selected transactions.</p>}
                                    />)}
                                {selectedRowsAreRefundable(selectedKeys, data) && (
                                    <PopConfirmWithNote
                                        isLoading={isrefunding}
                                        onCreate={handleRefund}
                                        formTitle="Issue refund"
                                        btnText="Refund Selected"
                                        btnType="primary"
                                        submitText="Refund"
                                        exitText="Exit"
                                    />)}
                            </>
                        </div>)}
                </SectionHeader>
                <Table
                    dataSource={dataSet}
                    locale={{ emptyText: 'No Transactions' }}
                    scroll={{ x: true }}
                    rowKey="id"
                    loading={false}
                    pagination={false}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                >
                    <Table.Column
                        align="center"
                        title="Transaction ID"
                        key="transactionId"
                        render={(_, record: Payment) => `${record.billerName}: ${record.billerTransactionId}`}
                    />

                    <Table.Column
                        align="center"
                        title="Date"
                        key="date"
                        dataIndex="date"
                        render={(_, record: Payment) => getDateTimeString(record.date)}
                    />

                    <Table.Column
                        align="center"
                        title="Type"
                        key="type"
                        dataIndex="type"
                        render={(text, record: Payment) => <TransactionTypeTag title={record.type} />}
                    />
                    <Table.Column
                        align="center"
                        title="Payment Status"
                        key="status"
                        render={(text, record: Payment) => <TransactionStatusTag status={record.status} />}
                    />
                    <Table.Column
                        key="amount"
                        align="center"
                        title="Amount"
                        render={(_, record: Payment) => {
                            if (!record.amount) return ''
                            return (
                                <HoverInfo
                                    center
                                    vals={billerAndOriginalAmount(record.currency, record.billerFee, record.originalAmount || record.amount)}
                                >
                                    ${record.amount}
                                </HoverInfo>
                            );
                        }}
                    />

                    <Table.Column
                        key="refunded"
                        align="center"
                        title="Refunded"
                        render={(text, record: Payment) =>
                            record.status === 'REFUNDED' ? findRefundedAmount(record.amount)
                                : (record.returnStatus === 'Refund Requested' || record.returnStatus === 'Refund Failed') ? record.returnStatus
                                    : ''
                        }
                    />

                    <Table.Column
                        key="Extra"
                        align="center"
                        title="Extra"
                        render={(text, record: Payment) =>
                            record.extras.length > 0 && (
                                <Tooltip
                                    title={record.extras.map((str, i) => (
                                        <span key={i}>{str}, </span>
                                    ))}
                                    color="var(--dark)"
                                >
                                    <InfoCircleOutlined
                                        style={{ fontSize: '16px', color: 'var(--green)' }}
                                        color="var(--green)"
                                    />
                                </Tooltip>
                            )
                        }
                    />
                </Table>
            </div>
        </Section>
    );
};

export default React.memo(TransactionsDetail);
