/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useCancelMembershipMutation } from '../services/customer';
import { fetchWithNotification } from '../utils/fetchWithNotification';

const useCancelMembership = () => {
    const [cancel, { isLoading, error }] = useCancelMembershipMutation();

    const cancelMembership = async (id: number) => {
        const result = await fetchWithNotification(() => cancel(+id), {
            success: 'Cancellation successful!',
            fail: 'Cancellation failed!',
        });
        return result;
    };

    return {
        cancelMembership,
        isCancelling: isLoading,
        cancelError: error,
    };
};

export default useCancelMembership;
