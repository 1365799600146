import { Descriptions, Typography } from 'antd';
import { UsageStats } from '../../types';
import SectionHeader from '../../components/ui/SectionHeader';
import { Section } from './customerDetail.styled';
import ExtraDetails from './ExtraDetails';

type UsageProps = {
    data: UsageStats;
};

const { Paragraph } = Typography;

const Usage: React.FC<UsageProps> = ({ data }) => {
    if (Object.keys(data).length < 1) {
        return (
            <Section as="section">
                <SectionHeader title="Usage" />
                <Paragraph> No usage stats</Paragraph>
            </Section>
        );
    }

    return (
        <Section as="section">
            <SectionHeader title="Usage" />
            <ExtraDetails text="View">
                <Descriptions size="middle" bordered column={{ md: 2, sm: 2, xs: 1 }}>
                    <Descriptions.Item label="Bandwidth">
                        {data.viewedTrialContentSize || 0 + data.viewedTrialContentSize || 0}
                    </Descriptions.Item>
                    <Descriptions.Item label="Thumbs Clicked">{data.thumbClicks}</Descriptions.Item>
                    <Descriptions.Item label="Viewed Trial Videos">{data.viewedTrialVideoPages}</Descriptions.Item>
                    <Descriptions.Item label="Viewed Videos">{data.viewedVideoPages}</Descriptions.Item>
                    <Descriptions.Item label="Video Downloads">{data.downloadedVideos}</Descriptions.Item>
                    <Descriptions.Item label="Photo Zip Download">{data.downloadedPhotosets}</Descriptions.Item>
                </Descriptions>
            </ExtraDetails>
        </Section>
    );
};

export default Usage;
