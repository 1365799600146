/* eslint-disable @typescript-eslint/no-use-before-define */
import { useState } from 'react';
import { GoogleLoginResponse, GoogleLoginResponseOffline } from 'react-google-login';
import * as Sentry from '@sentry/react';
import LoginForm from '../features/auth/LoginForm';
import { useGoogleLoginMutation, useLoginMutation } from '../services/auth';

export type GoogleResponse = GoogleLoginResponse | GoogleLoginResponseOffline;

const Login: React.FC = () => {
    const [login, { isLoading, error }] = useLoginMutation();
    const [googleLogin, { error: googleError }] = useGoogleLoginMutation();
    const [networkError, setNetworkError] = useState(false);
    const [googleLoading, setGoogleLoading] = useState(false);

    const submitHandler = async (values: { userName: string; password: string }) => {
        setNetworkError(false);
        try {
            await login({ username: values.userName, password: values.password });
        } catch (e: any) {
            Sentry.captureEvent(e);
            setNetworkError(true);
        }
    };

    const googleFailureHandler = (res: GoogleResponse & { error?: string }) => {
        Sentry.captureException(res);
        if (res.error && res.error !== 'idpiframe_initialization_failed') setNetworkError(true);
    };

    const googleSuccessHandler = async (res: GoogleResponse) => {
        const result = res as GoogleLoginResponse;
        setNetworkError(false);
        setGoogleLoading(true);
        // HERE WE VALIDATE THE GOOGLE TOKEN WITH THE BACKEND
        await googleLogin(`${result.tokenId}`);
    };

    const loginError = networkError || error || googleError;

    return (
        <LoginForm
            submit={submitHandler}
            errorMessage={loginError && 'Authentication failed. Please try again'}
            loading={isLoading || (googleLoading && !loginError)}
            googleSuccess={googleSuccessHandler}
            googleFailure={googleFailureHandler}
        />
    );
};

export default Login;
