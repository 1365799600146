/* eslint-disable prettier/prettier */
import { SearchPayload, SearchResponse } from './types';
import { crmApi } from '../crmApi';

const DEV_URI = process.env.REACT_APP_SEARCH_SERVICE ? `${process.env.REACT_APP_SEARCH_SERVICE}` : '/search-service';

export const searchApi = crmApi.injectEndpoints({
    endpoints: (builder) => ({
        search: builder.query<SearchResponse, SearchPayload>({
            query: (payload) =>
                `${DEV_URI}/search?q=${encodeURIComponent(payload.searchTerm)}&page=${payload.pageNumber}&size=${payload.pageSize
                }&sort=captureDate,desc`,
            providesTags: ['SearchResults'],
        }),
    }),
});

export const { useSearchQuery } = searchApi;
