/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useRefundTransactionsMutation } from '../services/customer';
import { fetchWithNotification } from '../utils/fetchWithNotification';

const useRefundTransactions = () => {
    const [refund, { isLoading, error }] = useRefundTransactionsMutation();

    const refundTransactions = async (ids: number[], customerId: string) => {
        if (!ids || ids.length < 1 || !customerId) return;
        const result = await fetchWithNotification(() => refund({ transactions: ids, id: customerId }), {
            success: 'Refund successful!',
            fail: 'Refund failed',
        });
        return result;
    };

    return {
        refundTransactions,
        isRefunding: isLoading,
        refundError: error,
    };
};

export default useRefundTransactions;
