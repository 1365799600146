import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useNavigate, createSearchParams, useSearchParams } from 'react-router-dom';
import ErrorAlertBox from '../components/ui/ErrorAlertBox';
import useCustomers from '../hooks/useCustomers';
import CustomersTable from '../features/customersList/CustomersTable';

type CustomersProps = {
    pageResultsSize?: number;
};

const Customers: React.FC<CustomersProps> = ({ pageResultsSize = 10 }) => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [inputValue, setInputValue] = useState('');
    const searchTermParameter = searchParams.get('q');
    const searchTermParamChecked = useRef(false);
    const {
        data,
        searchResultsLoading,
        searchSummaryLoading,
        setSearchQuery,
        isError,
        errorMessage,
        searchQuery,
        changePage,
        changePageSize,
        totalElements,
        pageSize,
        currentPage,
    } = useCustomers(pageResultsSize);

    // FOR LOADING URLS WITH QUERY PARAMS
    useEffect(() => {
        if (searchTermParamChecked.current) return;
        if (searchTermParameter) {
            setSearchQuery(searchTermParameter);
            setInputValue(searchTermParameter);
        }
        searchTermParamChecked.current = true;
    }, [searchTermParameter, setSearchQuery]);

    // UPDATE QUERY AND SEARCH PARAMS
    const onSearch = (val: string) => {
        if (val !== searchQuery) changePage(1, pageSize);
        setSearchQuery(val);
        navigate({
            pathname: '/',
            search: `?${createSearchParams({
                q: val,
            })}`,
        });
    };

    const onChange = (event: ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
        if (event.target.value === '') setSearchQuery('');
    };

    if (isError)
        return (
            <ErrorAlertBox
                message="There was an error making this search request"
                errorInfo={errorMessage}
                callback={() => setSearchQuery('')}
            />
        );

    return (
        <CustomersTable
            onSearch={onSearch}
            onInputChange={onChange}
            changePage={changePage}
            changePageSize={changePageSize}
            searchResultsLoading={searchResultsLoading}
            searchSummaryLoading={searchSummaryLoading}
            inputValue={inputValue}
            totalElements={totalElements}
            data={data}
            currentPage={currentPage + 1}
            pageSize={pageSize}
        />
    );
};

export default Customers;
