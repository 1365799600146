import { Route, Routes } from 'react-router-dom';
import CustomerDetails from './CustomerDetails';
import Customers from './CustomersList';

const AppRoutes: React.FC = () => (
    <Routes>
        <Route path="/" element={<Customers />} />
        <Route path="/customers" element={<Customers />} />
        <Route path="/customers/:id" element={<CustomerDetails />} />
    </Routes>
);

export default AppRoutes;
